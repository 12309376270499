import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 42;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>
          {" "}
          <a
            href="https://www.amazon.co.jp/stores/page/5ECA5B41-0270-4B2C-A79D-62ED8C6C8CA0?ingress=0&visitId=66e5154c-d709-4618-9352-5b740e91025c"
            target="_blank"
            rel="noopener noreferrer"
          >
            リバーライト 極ジャパンのフライパン
          </a>
        </li>
        <li>ステンレスフライパンに変えた理由</li>
        <li>ステンレスフライパンのデメリット</li>
        <li>鉄だけどメンテナンスが不要なフライパン</li>
        <li>素人が業務用ガスコンロを使うとどうなるか</li>
        <li>ステンレスフライパンでこびりつかずに卵を焼くコツ</li>
        <li>チャーハンだけは難しい</li>
        <li>フレンチのイメージは王様のレストラン</li>
        <li>オーブンのある生活はやめられない</li>
        <li>箕澤屋にオーブンを入れなかった理由</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
